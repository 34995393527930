import { useState } from "react"

/**
 * authUserファイルの存在チェック
 */
export const useLocalAuthFile = () => {
  let auth
  if (typeof window !== 'undefined') {
    auth = localStorage.getItem("authUser")
  }
  let isLogin, userType
  if (auth) {
    isLogin = true
    userType = JSON.parse(auth).userType
  } else {
    isLogin = false
    userType = null
  }

  // console.log("localAuthUser", JSON.parse(auth).userType)

  // TODO:仮　※後からストレージ内容を見るように修正予定
  return (
    {
      isLogin: isLogin,
      userType: userType
    }
  )
}

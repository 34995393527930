import * as React from "react"
import { ITitle } from "./ITitle"

export const Title = React.memo((props: ITitle) => {
  return (
    <div className="ir_sub_title">
      <span>{props.title}</span>
    </div>
  )
})

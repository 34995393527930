import { useEffect, useState } from "react"
import { ICommonData } from "../components/organisms/common/ICommonData"
import { IStep3 } from "../components/templates/AddProduct/step3/IStep3"
import { SEARCH_USER, SUBMIT_ACCOUNT } from "./apiConstants"
import { get, getUserType, post } from "./utils"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "./constants"
import { IAccountForm } from "../components/organisms/AccountForm/IAccountForm"

export const useCommonData = () => {
  const [ commonDataLocal, setCommonDataLocal ] = useState<ICommonData>(null)

  useEffect(() => {
    // データ存在チェック
    if (!commonDataLocal) {
      const value = localStorage.getItem("commonData")
      if (value) {
        setCommonDataLocal(JSON.parse(value))
      }
    }
  }, [])

  const setCommonData = (data: ICommonData) => {
    localStorage.setItem("commonData", JSON.stringify(data))
    setCommonDataLocal(data)
  }

  return [ commonDataLocal, setCommonData ]
}

export const useAccount = () => {
  const [ accountData, setAccountData ] = useState<IAccountForm[]>(null)

  useEffect(() => {
    (async () => {
      if (!accountData) {
        console.log("useAccount if true")
        await getAccount("").catch(e => alert(e))
      } else {
        console.log("useAccount if false")
      }
    })()
  }, [])

  const getAccount = async (keyword) => {
    let paramObj = {params: {}}
    paramObj.params.keyword = keyword
    const memberList = await get(SEARCH_USER, paramObj).catch(e => console.log("e", e))
    setAccountData(memberList.result)
  }

  const addAccount = async (data) => {
    // 登録・更新処理
    let paramObj = {}
    paramObj.name = data.name
    paramObj.mail = data.mail
    paramObj.password = data.password
    paramObj.password_confirmation = data.password
    let accountType
    const userType = getUserType()
    switch (parseInt(userType)) {
      case BUYER_ADMIN:
        accountType = 0
        break
      case MAKER_ADMIN:
        accountType = 10
        break
      case ADMIN:
        accountType = data.user_type === BUYER_ADMIN ? BUYER_ADMIN : MAKER_ADMIN
        break
      default:
        accountType = 0
        break
    }
    paramObj.type = accountType
    console.log("paramObj", paramObj)
    await post(SUBMIT_ACCOUNT, paramObj).then(response => {
      console.log("SUBMIT_ACCOUNT response", response)
    }).catch(e => {
      alert(e.message)
    })
  }

  return [ accountData, addAccount, getAccount ]
}

/**
 * 商品登録画面用カスタムフック
 * @param product_id 商品ID
 */
export const useProduct = (product_id) => {
  const [ productData, setProductData ] = useState<IStep3>(null)

  useEffect(() => {
    // 商品IDがすでに存在する場合は取得
    if (product_id) {
      // データ取得処理
      // setProductData(await get("products", {product_id}))
    }
  }, [])

  /**
   * 商品情報をポストする
   * @param api_to 実行API名
   * @param data 商品情報
   */
  const submitProduct = (api_to, data) => {
    // 登録・更新処理
    // setProductData()
  }

  return [ productData, submitProduct ]
}
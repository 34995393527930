import * as React from "react"
import { ADMIN, APPROVAL_TYPES, BUYER_ADMIN, EXCEPTION_NAME, MAKER_ADMIN } from "../common/constants"
import { Chip } from "@material-ui/core"
import { axiosAPI, axiosAPIUnAuth } from "../../api_helper"
import { SESSION_ERROR } from "../store/auth/actionType"
import store from "../store/store"
import { BREADCRUMB_PATH } from "./routePath"
import { navigate } from "gatsby"
import Dexie from "dexie"
import {
  FAVORITES, HOME,
  PRODUCT_ALL_INFORMATION,
  PRODUCT_REGISTER,
  PRODUCT_REGISTER_FORM,
  PRODUCT_UPDATE,
} from "./apiConstants"
import axios from "axios"
import * as _ from "lodash"

export const isBrowser = typeof window !== "undefined"

/**
 * 管理者判定
 */
export const isAdmin = () => {
  let rtnIsAdmin = false
  const localUser = JSON.parse(localStorage.getItem("authUser"))
  if (localUser) {
    rtnIsAdmin = localUser.userType == ADMIN
  } else {
    store.dispatch({
      type: SESSION_ERROR
    })
  }
  return rtnIsAdmin
}

export const isCompanyAdmin = () => {
  let rtnIsCompanyAdmin = false
  const localUser = JSON.parse(localStorage.getItem("authUser"))
  if (localUser) {
    rtnIsCompanyAdmin = localUser.userType == BUYER_ADMIN || localUser.userType == MAKER_ADMIN
    console.log("localUser.userType", localUser.userType, rtnIsCompanyAdmin)
  } else {
    store.dispatch({
      type: SESSION_ERROR
    })
  }
  return rtnIsCompanyAdmin
}

/**
 * ユーザータイプを返す
 */
export const getUserType = () => {
  let rtnUserType
  const localUser = JSON.parse(localStorage.getItem("authUser"))
  if (localUser) {
    rtnUserType = localUser.userType
  } else {
    store.dispatch({
      type: SESSION_ERROR
    })
  }
  return rtnUserType
}

/**
 * ログインユーザーを返す
 */
export const getLocalUser = () => {
  const localUser = JSON.parse(localStorage.getItem("authUser"))
  if (!localUser) {
    alert("getLocalUser SESSION_ERROR")
    store.dispatch({
      type: SESSION_ERROR
    })
  }

  return localUser
}

/**
 * 承認ステータス用チップを返す
 * @param status_type
 * @param size (0:medium、1:small)
 * @param className ('orangeChip'、'greyChip'、'redChip')
 * @returns {JSX.Element}
 */
export const getApprovalChip = (status_type, size, className) => {
  return <Chip label={APPROVAL_TYPES[status_type]} className={className} size={size ? "medium" : "small"} />
}

export async function appLogin(email, password) {
  await axiosAPI.get("csrf-cookie")
  return await axiosAPI.post("login", { email: email, password: password }).then(response => {
    console.log("login response", response)
    localStorage.removeItem("authUser")
    localStorage.setItem("authUser", JSON.stringify({
      userId: response["id"],
      userType: response["type"],
      userName: response["name"],
      userEmail: response["email"],
      companyId: response["company_id"],
      company: response["company"]
    }))

    return response
  }).catch((e) => {
    console.log("e", e)
  })
}

export async function appLogout() {
  return await axiosAPI.post("logout").then(response => {
    localStorage.removeItem("authUser")
    localStorage.removeItem("commonData")
  })
}

export const getDealingStatus = (buyer_adopted_at, buyer_closed_at, maker_adopted_at, maker_closed_at) => {
  let rtnStatus = 0

  if (buyer_adopted_at && buyer_closed_at && maker_adopted_at && maker_closed_at) {
    // 両社共に成立、終了に日付がある場合は成立
    rtnStatus = 9
  } else if (!buyer_adopted_at && buyer_closed_at && !maker_adopted_at && maker_closed_at) {
    // 両社共に成立が空、終了に日付がある場合は終了
    rtnStatus = 1
  }

  return rtnStatus
}

export async function get(url, config = {}) {
  return await axiosAPI.get(url, config).then(response => response)
}

export async function post(url, data, config = {}) {
  return axiosAPI.post(url, data, { ...config }).then(response => response.data)
}

export async function post2(url, data, config = {}) {
  return axiosAPI.post(url, data, { ...config }).then(response => response)
}

export async function unAuthPost(url, data, config = {}) {
  await axiosAPI.get("csrf-cookie")
  return axiosAPIUnAuth.post(url, data, { ...config }).then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosAPI.delete(url, { ...config }).then(response => response.data)
}

export const returnIncrementName = (name: string) => {
  let retVal = ""
  console.log("returnIncrementName", name)
  const attributeNumber = name.toString().match(/_\d$/g)
  let suffix = 2
  if (attributeNumber) {
    suffix = Number(attributeNumber[0].replace("_", "")) + 1
    retVal = name.replace(attributeNumber[0], `_${suffix}`)
  } else {
    retVal = `${name}_${suffix}`
  }

  console.log("returnIncrementName return", retVal)
  return retVal
}

/**
 * 改行コードをbrタグに変換する
 * @param text
 */
export const changeMultipleLines = (text) => {
  let texts
  if (text) {
    if (text !== null && !(text instanceof Array) && text !== undefined && text.match("\n")) {
      texts = text.split("\n").map((item, index) => {
        return (
          <React.Fragment key={index}>{item}<br /></React.Fragment>
        )
      })
    } else {
      texts = text
    }
    return <span>{texts}</span>
  } else {
    return <span></span>
  }
}

export const renderText = (text) => {
  let tmpTxt = changeMultipleLines(text)
  return <div className="view_value" children={tmpTxt} />
}

export const renderTextAtCaution = (text) => {
  let tmpTxt
  let cautionTxt
  if (text !== null && !(text instanceof Array) && text !== undefined && text.match("\n")) {
    tmpTxt = text.split("\n")[0]
    cautionTxt = text.split("\n")[1]
  } else {
    tmpTxt = text
  }
  return (
    <>
      <div className="view_value">{tmpTxt}</div>
      {cautionTxt ? <div className="cautionTitle">{cautionTxt}</div> : null}
    </>
  )
}

/**
 * 画面名を返す
 * @param path
 */
export const getPageName = (path) => {
  const mstPathList = BREADCRUMB_PATH
  let rtnViewName
  Object.keys(mstPathList).map((key, index) => {
    if (path === mstPathList[key]) {
      rtnViewName = key
    }
  })
  !rtnViewName && path !== "/" ? rtnViewName = "Top" : ""
  return rtnViewName
}

/**
 * blobUrlをFileへ変換
 * @param url
 */
export const convertBlobToFile = async (fineName, url) => {
  const file = await fetch(url).then((res) => res.blob())
    .then((myBlob) => {
      const myFile = new File([ myBlob ], fineName, {
        type: myBlob.type,
      })
      return myFile
    })
  return file
}

export const convertDataURItoBlob = (dataURI: string) => {
  let byteString
  if (dataURI.split(',')[0].includes('base64'))
    byteString = atob(dataURI.split(',')[1])
  else
    byteString = unescape(dataURI.split(',')[1])

  const mimeString = dataURI ? dataURI.split(',')[0].split(':')[1].split(';')[0] : ""

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ ia ], { type: mimeString })
}

export const convertDataURItoBlobUrl = (dataURI: string) => {
  let byteString
  if (dataURI.split(',')[0].includes('base64'))
    byteString = atob(dataURI.split(',')[1])
  else
    byteString = unescape(dataURI.split(',')[1])

  const mimeString = dataURI ? dataURI.split(',')[0].split(':')[1].split(';')[0] : ""

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const newblob = new Blob([ ia ], { type: mimeString })
  let rtnUrl = dataURI ? URL.createObjectURL(newblob) : ""
  return rtnUrl
}

export const addOnlyLocalDB = async (formName, data) => {
  const db = new Dexie("vendinDB")
  db.version(1).stores({
    productForm: 'formName',
  })
  db.open()
  db.productForm.delete(formName)
  db.productForm.put({
    formName: formName,
    form: data
  })
}

export const addLocalDB = async (formName, data, goto) => {
  const db = new Dexie("vendinDB")
  db.version(1).stores({
    productForm: 'formName',
  })
  db.open()
  db.productForm.delete(formName)
  db.productForm.put({
    formName: formName,
    form: data
  }).then(() => {
    navigate(goto)
  })
}

export const deleteStore = async () => {
  let openReq = await indexedDB.open("vendinDB")
  openReq.onsuccess = (event) => {
    try {
      let db = (event.target as IDBOpenDBRequest).result
      let trans = db.transaction("productForm", "readwrite")
      let store = trans.objectStore("productForm")
      let delRes = store.clear()

      delRes.onsuccess = () => {
        console.log("vendinDB-削除完了")
      }
    } catch (e) {
      return false
    }
  }
}

export const onDraftSubmit = async (formName, data) => {
  let stepObj = {}
  let resId

  let paramObj
  const localForm = JSON.parse(localStorage.getItem("product_forms"))
  // localDBにカレント画面のデータをinsert
  await addOnlyLocalDB(formName, data)

  // localDBからデータ取得
  const db = new Dexie("vendinDB")
  db.version(1).stores({ productForm: 'formName', })
  db.open()
  await db.productForm.orderBy('formName').toArray().then(async res => {
    // カレント画面のデータを整形
    res.map((form, index) => {
      switch (form.formName) {
        case "step1":
          stepObj["step1"] = JSON.parse(form.form)
          break
        case "step2":
          stepObj["step2"] = JSON.parse(form.form)
          break
        case "step3":
          stepObj["step3"] = formatVariableForm(JSON.parse(form.form))
          break
        case "step4":
          stepObj["step4"] = formatVariableForm(JSON.parse(form.form))
          break
        case "step5":
          stepObj["step5"] = JSON.parse(form.form)
          break
        case "step6":
          stepObj["step6"] = JSON.parse(form.form)
          break
        case "step7":
          stepObj["step7"] = JSON.parse(form.form)
          break
        case "step8":
          stepObj["step8"] = JSON.parse(form.form)
          break
        case "step9":
          stepObj["step9"] = JSON.parse(form.form)
          break
        case "step10":
          stepObj["step10"] = JSON.parse(form.form)
          break
        case "step11":
          stepObj["step11"] = JSON.parse(form.form)
          break
        case "step12":
          stepObj["step12"] = JSON.parse(form.form)
          break
      }
    })

    const stepWrap = {
      step1: { ...stepObj["step1"] },
      step2: { ...stepObj["step2"] },
      step3: { ...stepObj["step3"] },
      step4: { ...stepObj["step4"] },
      step5: { ...stepObj["step5"] },
      step6: { ...stepObj["step6"] },
      step7: { ...stepObj["step7"] },
      step8: { ...stepObj["step8"] },
      step9: { ...stepObj["step9"] },
      step10: { ...stepObj["step10"] },
      step11: { ...stepObj["step11"] },
      step12: { ...stepObj["step12"] }
    }
    let diffFlg = false
    if (stepObj["step2"].id) {
      const stepDiffs =  await getProduct(stepObj["step2"].id, stepWrap)
      stepDiffs.map((stepDiff, index) => {
        if (JSON.stringify(stepDiff.diff) !== '{}') {
          Object.keys(stepDiff).map((key, i) => {
            if (!EXCEPTION_NAME.includes(key)) {
              diffFlg = true
            }
          })
        }
      })
    }

    paramObj = {
      product_info: {
        step1: { ...stepObj["step1"] },
        step2: { ...stepObj["step2"] },
        step3: stepObj["step3"] ? { ...stepObj["step3"] } : formatVariableForm(localForm.product_info.step3),
        step4: stepObj["step4"] ? { ...stepObj["step4"] } : formatVariableForm(localForm.product_info.step4),
        step5: stepObj["step5"] ? { ...stepObj["step5"] } : localForm.product_info.step5,
        step6: stepObj["step6"] ? { ...stepObj["step6"] } : localForm.product_info.step6,
        step7: stepObj["step7"] ? { ...stepObj["step7"] } : localForm.product_info.step7,
        step8: stepObj["step8"] ? { ...stepObj["step8"] } : localForm.product_info.step8,
        step9: stepObj["step9"] ? { ...stepObj["step9"] } : localForm.product_info.step9,
        step10: stepObj["step10"] ? { ...stepObj["step10"] } : localForm.product_info.step10,
        step11: stepObj["step11"] ? { ...stepObj["step11"] } : localForm.product_info.step11,
        step12: stepObj["step12"] ? { ...stepObj["step12"] } : localForm.product_info.step12
      },
      is_draft: true,
      is_revoke: diffFlg,
    }

    console.log("draft param", paramObj)
    if (stepObj["step2"].id) {
      await post(PRODUCT_UPDATE, paramObj).catch(e => {
        console.log("PRODUCT_UPDATE", e)
      })
    } else {
      await post2(PRODUCT_REGISTER, paramObj).then(response => {
        console.log("PRODUCT_REGISTER　success draft", response)
        resId = response
      }).catch(e => {
        console.log("PRODUCT_REGISTER error draft", e)
      })
    }

  }).catch(e => {
    console.log("getLocalDB e", e)
  })

  return resId
}

export const formatVariableForm = (stepForm) => {
  let copyForm = _.cloneDeep(stepForm)
  let valueObj = Object.values(copyForm)

  if (valueObj.length >= 1) {
    valueObj.forEach((dataRow, index) => {
      let copyRow = Object.values(dataRow["forms"])
      copyRow.forEach((form, idx) => {
        form["attributes"].forEach((item, i) => {
          item.style = item.style ? JSON.stringify(item.style) : ""
          item.rules = item.rules ? JSON.stringify(item.rules) : ""
          item.selects = item.selects ? JSON.stringify(item.selects) : ""
          if (item.input_type === "7" || item.input_type === "8") {
            item.value = item.value ? JSON.stringify(item.value) : ""
          } else {
            item.value = item.value ? item.value.toString() : ""
          }
        })
      })
    })
  }
  return copyForm
}

export const formatVariableFormToJson = (stepForm) => {
  let copyForm = _.cloneDeep(stepForm)
  let valueObj = Object.values(copyForm)

  if (valueObj.length >= 1) {
    valueObj.forEach((dataRow, index) => {
      let copyRow = Object.values(dataRow["forms"])
      copyRow.forEach((form, idx) => {
        form["attributes"].forEach((item, i) => {
          item.style = item.style ? JSON.parse(item.style) : ""
          item.rules = item.rules ? JSON.parse(item.rules) : ""
          item.selects = item.selects ? JSON.parse(item.selects) : ""
          if (item.input_type === "4") {
            item.value = item.value.split(",")
          } else if (item.input_type === "2" || item.input_type === "3") {
            item.value = item.value.toString()
          }
        })
      })
    })
  }
  return copyForm
}

const diffProps = (stateObj, dbObj) => {
  const diffProps = _.reduce(stateObj, (result, value, key) => {
    return _.isEqual(value, dbObj[key]) ? result : result.concat(key)
  }, [])

  const diffObj = diffProps.reduce((obj, prop) => {
    obj[prop] = dbObj[prop]
    return obj
  }, {})

  return diffObj
}

export const getProduct = async (product_id, stepWrap) => {
  let rtnDiff = []
  let paramObj = { params: {} }
  paramObj.params.id = product_id
  await get(PRODUCT_ALL_INFORMATION, paramObj).then(response => {
    const productInfo = response.product_info
    const dbDataKeys = Object.keys(productInfo)
    dbDataKeys.map((key, index) => {
      switch (key) {
        case "step1":
          let step1Diff = diffProps(stepWrap.step1, productInfo[key])
          rtnDiff.push({diff: step1Diff})
          break
        case "step2":
          // let step2Diff = _.omitBy(productInfo[key], (v, k) => step2Form[k] === v)
          let step2Diff = diffProps(stepWrap.step2, productInfo[key])
          rtnDiff.push({diff: step2Diff})
          break
        case "step3":
          let step3Diff = diffProps(stepWrap.step3, productInfo[key])
          rtnDiff.push({diff: step3Diff})
          break
        case "step4":
          let step4Diff = diffProps(stepWrap.step4, productInfo[key])
          rtnDiff.push({diff: step4Diff})
          break
        case "step5":
          let step5Diff = diffProps(stepWrap.step5, productInfo[key])
          rtnDiff.push({diff: step5Diff})
          break
        case "step6":
          let step6Diff = diffProps(stepWrap.step6, productInfo[key])
          rtnDiff.push({diff: step6Diff})
          break
        case "step7":
          let step7Diff = diffProps(stepWrap.step7, productInfo[key])
          rtnDiff.push({diff: step7Diff})
          break
        case "step8":
          let step8Diff = diffProps(stepWrap.step8, productInfo[key])
          rtnDiff.push({diff: step8Diff})
          break
        case "step9":
          let step9Diff = diffProps(stepWrap.step9, productInfo[key])
          rtnDiff.push({diff: step9Diff})
          break
        case "step10":
          let step10Diff = diffProps(stepWrap.step10, productInfo[key])
          rtnDiff.push({diff: step10Diff})
          break
        case "step11":
          let step11Diff = diffProps(stepWrap.step11, productInfo[key])
          rtnDiff.push({diff: step11Diff})
          break
        case "step12":
          let step12Diff = diffProps(stepWrap.step12, productInfo[key])
          rtnDiff.push({diff: step12Diff})
          break
      }
    })
  }).catch(e => {
    console.log("e", e)
  })

  return rtnDiff
}

export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

export const delInLocalDbProducts = async (product_id) => {

  // ドラフト保存したデータを取得し直し
  let paramObj = { params: {} }
  paramObj.params.id = product_id
  await get(PRODUCT_ALL_INFORMATION, paramObj).then(async response => {

    console.log("delInLocalDbProducts", response)

    // localStrageの「product_forms」を削除
    localStorage.removeItem("product_forms")
    // localDBのデータを一回クリア
    await deleteStore()

    // カテゴリに応じた「product_forms」をlocalstrageへ再登録
    let paramObj = { params: {} }
    paramObj.params.id = Number(response.product_info.step1["category"])
    const data = await get(PRODUCT_REGISTER_FORM, paramObj).catch(e => console.log("PRODUCT_REGISTER_FORM", e))
    localStorage.setItem("product_forms", JSON.stringify(data))

    const productInfo = response["product_info"]
    // propsのproduct_infoをlocalDBへ保存
    const infoKeys = Object.keys(productInfo)
    infoKeys.map(async (key, index) => {
      let copyData
      if (key === "step3" || key === "step4") {
        console.log("productInfo[key]", productInfo[key])
        copyData = formatVariableFormToJson(productInfo[key])
        console.log("productInfo[key] copyData", copyData)
      } else {
        copyData = productInfo[key]
      }
      await addOnlyLocalDB(key, JSON.stringify(copyData))
    })
  })
}

export const matchFavorite = async () => {
  let favoriteIdList = []
  await get(FAVORITES, {}).then(response => {
    response.map((favorite, index) => {
      console.log("favorite",favorite)
      favoriteIdList.push(favorite.product_id)
    })
  }).catch(e => console.log("matchFavorite", e))
  return favoriteIdList
}

export const getHomeData = async () => {
  let homeData
  await get(HOME, {}).then(response => {
    homeData = response
  }).catch(e => console.log("matchFavorite", e))
  return homeData
}

export const setHeaderCommonData = async () => {
  const commonData = localStorage.getItem("commonData")
  let tempObj = JSON.parse(commonData)
  // homeの件数取得
  await getHomeData().then(homeUnread => {
    if (homeUnread) {
      tempObj.header.unread_dealing = homeUnread.dealing_message_unread_count ? homeUnread.dealing_message_unread_count : 0
      tempObj.header.unread_message = homeUnread.notice_unread_count ? homeUnread.notice_unread_count : 0
    }
  })

  localStorage.removeItem("commonData")
  localStorage.setItem("commonData", JSON.stringify(tempObj))
}

export const getSellingStatusStyle = (status) => {
  if (status == 0) {
    return "remand"
  } else if (status == 1) {
    return "open"
  }
  return ""
}

export const formatItemId = (name) => {
  return name.split(".").pop()
}
export const PRODUCTS = "products"
export const CATEGORIES = "categories"
export const SUB_CATEGORIES = "subCategories"
export const SUBMIT_ACCOUNT = "storeUser"
export const USER_UPDATE = "user/update"
export const USER_CREATE = "user/create"
export const SEARCH_USER = "searchUser"
export const MESSAGE_NOTICES = "notices"
export const MESSAGE_NOTICE = "notice"
export const COMPANIES = "companies"
export const COMPANY_DETAIL = "company_detail"
export const COMPANY = "company"
export const MAKER_PRODUCTS = "maker/products"
export const PRODUCT_REGISTER_FORM = "product_register_form"
export const DEALINGS = "dealings"
export const DEALING = "dealing"
export const DEALING_START = "dealing/start"
export const DEALING_POST_MESSAGE = "dealing/post_message"
export const DEALING_ADOPT = "dealing/adopt"
export const DEALING_CLOSE = "dealing/close"
export const MESSAGE_NOTICE_UPSERT = "notice/upsert"
export const MESSAGE_NOTICE_DELETE = "notice/delete"
export const PRODUCT_REGISTER = "product_register"
export const PRODUCT_UPDATE = "product_update"
export const FAVORITES = "favorites"
export const FAVORITE = "favorite"
export const PRODUCT_ALL_INFORMATION = "product_all_information"
export const PRODUCT_REVIEW = "product_review"
export const PRODUCT_QA_LIST = "product/qa_list"
export const PRODUCT_QA_DETAIL = "product/qa_detail"
export const PRODUCT_QA_DELETE = "product/qa_delete"
export const PRODUCT_QUESTION = "product/question"
export const PRODUCT_ANSWER = "product/answer"
export const HOME = "home"
export const LOST_ACCOUNT = "lost_account"
export const CONTACT = "contact"
export const BRAND_REGISTER = "brand_register"
export const DOCUMENT_REQUEST = "document_request"
export const PRODUCT_DELETE = "product_delete"
export const COMPANY_SUBSCRIPTION = "company/subscription"
export const FORCE_WITHDRAW = "force_withdraw"
export const WITHDRAW = "withdraw"
import * as React from "react"
import { IDescription } from "./IDescription"

export const Description = React.memo((props: IDescription) => {
  return (
    <div className="ir_info_text">
      <span>{props.description}</span>
    </div>
  )
})

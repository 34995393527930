import * as React from "react"
import Login from "../..//components/templates/Authentication/Login";
import { FunctionComponent } from "react"
import { RouteProps } from "react-router"

// markup
const login: FunctionComponent<RouteProps> = () => {
    return (
        <Login />
    )
}

export default login
import Product from "../components/pages/product/product"

/**
 * from app
 */
export const FROM_APP_PATH = {
  ADMIN_TOP: "/admin",
  MAKER_TOP: "/maker",
  BUYER_TOP: "/buyer",
  MAKER_LIST: "/makerlist",
  MAKER: "/makerDetail",
  MAKER_PRODUCT: "/makerProduct",
  ACCOUNT: "/account",
  MEMBER: "/member",
  COMPANY: "/company",
  COMPANY_BRAND: "/companyBrand",
  ADMIN_COMPANY: "/adminCompany",
  ADMIN_LIST: "/adminList",
  NOTIFICATIONS: "/notifications",
  NOTIFICATION_DETAIL: "/notification",
  NOTIFICATION_DETAIL_EDIT: "/notification/edit",
  DEALINGS: "/dealings",
  DEALING_DETAIL: "/dealing",
  APPROVALS: "/approvals",
  APPROVAL: "/approval",
  QUESTIONS: "/questions",
  QUESTION: "/question",
  CREATED_PRODUCTS: "/created",
  PRODUCTS: "/products",
  PRODUCT: "/Product",
  FAVORITES: "/favorites",
  STEP1: "/step1",
  STEP2: "/step2",
  STEP3: "/step3",
  STEP4: "/step4",
  STEP5: "/step5",
  STEP6: "/step6",
  STEP7: "/step7",
  STEP8: "/step8",
  STEP9: "/step9",
  STEP10: "/step10",
  STEP11: "/step11",
  STEP12: "/step12",
  STEP13: "/step13",
  FINISH: "/finish",
}

/**
 * relative
 */
export const RELATIVE_PATH = {
  ADMIN_TOP: "/app/admin",
  MAKER_TOP: "/app/maker",
  BUYER_TOP: "/app/buyer",
  MAKER_LIST: "/app/makerlist",
  MAKER: "/app/makerDetail",
  MAKER_PRODUCT: "/app/makerProduct",
  ACCOUNT: "/app/account",
  MEMBER: "/app/member",
  COMPANY: "/app/company",
  COMPANY_BRAND: "/app/companyBrand",
  ADMIN_COMPANY: "/app/adminCompany",
  ADMIN_LIST: "/app/adminList",
  NOTIFICATIONS: "/app/notifications",
  NOTIFICATION_DETAIL: "/app/notification",
  NOTIFICATION_DETAIL_EDIT: "/app/notification/edit",
  DEALINGS: "/app/dealings",
  DEALING_DETAIL: "/app/dealing",
  APPROVALS: "/app/approvals",
  APPROVAL: "/app/approval",
  QUESTIONS: "/app/questions",
  QUESTION: "/app/question",
  CREATED_PRODUCTS: "/app/created",
  PRODUCTS: "/app/products",
  PRODUCT: "/app/Product",
  FAVORITES: "/app/favorites",
  STEP1: "/app/step1",
  STEP2: "/app/step2",
  STEP3: "/app/step3",
  STEP4: "/app/step4",
  STEP5: "/app/step5",
  STEP6: "/app/step6",
  STEP7: "/app/step7",
  STEP8: "/app/step8",
  STEP9: "/app/step9",
  STEP10: "/app/step10",
  STEP11: "/app/step11",
  STEP12: "/app/step12",
  STEP13: "/app/step13",
  FINISH: "/app/finish",
}

/**
 * relative
 */
export const BREADCRUMB_PATH = {
  "メーカー一覧": "/app/makerlist",
  "メーカー詳細": "/app/makerDetail/",
  "メーカー商品": "/app/makerProduct/",
  "プロフィール": "/app/account/",
  "担当者一覧": "/app/member/",
  "会社情報": "/app/company/",
  "公開情報設定": "/app/company/",
  "会社一覧": "/app/adminCompany",
  "会社管理者一覧" : "/app/adminList",
  "お知らせ一覧": "/app/notifications",
  "お知らせ詳細": "/app/notification/",
  "お知らせ登録": "/app/notification/edit",
  "商談一覧": "/app/dealings/",
  "商談詳細": "/app/dealing/",
  "申請一覧": "/app/approvals/",
  "QA一覧": "/app/questions/",
  "QA詳細": "/app/question/",
  "登録商品一覧": "/app/created",
  "商品一覧": "/app/products/",
  "お気に入り一覧": "/app/favorites",
  "カテゴリ選択": "/app/step1",
  "基本情報": "/app/step2",
  "仕様": "/app/step3",
  "分類別情報": "/app/step4",
  "返品サポート情報": "/app/step5",
  "セールスポイント商品概要": "/app/step6",
  "セールスポイント効果": "/app/step7",
  "セールスポイント実績": "/app/step8",
  "セールスポイント基本": "/app/step9",
  "セールスポイント実演": "/app/step10",
  "セールスポイント出演情報": "/app/step11",
  "その他": "/app/step12",
  "確認": "/app/step13",
  "完了": "/app/finish",
}

export const SITE_MAP = {
  "/app/admin" : [
    { "Top": "" },
  ],
  "/app/maker" : [
    { "Top": "" },
  ],
  "/app/buyer" : [
    { "Top": "" },
  ],
  "/app/makerlist" : [
    { "Top": "/app" },
    { "メーカー一覧": "" },
  ],
  "/app/makerlist" : [
    { "Top": "/app" },
    { "メーカー一覧": "" },
  ],
  "/app/makerlist" : [
    { "Top": "/app" },
    { "メーカー一覧": "" },
  ],
  "/app/makerDetail/" : [
    { "Top": "/app" },
    { "メーカー一覧": "/app/makerlist" },
    { "メーカー詳細": "" },
  ],
  "/app/makerProduct/" : [
    { "Top": "/app" },
    { "メーカー一覧": "/app/makerlist" },
    { "メーカー商品": "" },
  ],
  "/app/account" : [
    { "Top": "/app" },
    { "アカウント情報": "" },
  ],
  "/app/member" : [
    { "Top": "/app" },
    { "担当者アカウント情報": "" },
  ],
  "/app/company" : [
    { "Top": "/app" },
    { "公開情報": "" },
  ],
  "/app/adminList" : [
    { "Top": "/app" },
    { "会社管理者一覧": "" },
  ],
  "/app/notifications" : [
    { "Top": "/app" },
    { "お知らせ一覧": "" },
  ],
  "/app/notification/" : [
    { "Top": "/app" },
    { "お知らせ一覧": "/app/notifications" },
    { "お知らせ詳細": "" },
  ],
  "/app/notification/edit" : [
    { "Top": "/app" },
    { "お知らせ一覧": "/app/notifications" },
    { "お知らせ登録": "" },
  ],
  "/app/dealings" : [
    { "Top": "/app" },
    { "商談一覧": "" },
  ],
  "/app/dealing" : [
    { "Top": "/app" },
    { "商談一覧": "/app/dealings" },
    { "商談詳細": "" }
  ],
  "/app/approvals" : [
    { "Top": "/app" },
    { "申請一覧": "" },
  ],
  "/app/questions" : [
    { "Top": "/app" },
    { "QA一覧": "" },
  ],
  "/app/favorites" : [
    { "Top": "/app" },
    { "お気に入り一覧": "" },
  ],
  "/app/step1" : [
    { "Top": "/app" },
    { "カテゴリ選択": "" },
  ],
  "/app/step2" : [
    { "Top": "/app" },
    { "基本情報": "" },
  ],
  "/app/step3" : [
    { "Top": "/app" },
    { "仕様": "" },
  ],
  "/app/step4" : [
    { "Top": "/app" },
    { "分類別情報": "" },
  ],
  "/app/step5" : [
    { "Top": "/app" },
    { "返品・サポート情報": "" },
  ],
  "/app/step6" : [
    { "Top": "/app" },
    { "商品概要": "" },
  ],
  "/app/step7" : [
    { "Top": "/app" },
    { "効果": "" },
  ],
  "/app/step8" : [
    { "Top": "/app" },
    { "実績": "" },
  ],
  "/app/step9" : [
    { "Top": "/app" },
    { "基本": "" },
  ],
  "/app/step10" : [
    { "Top": "/app" },
    { "実演": "" },
  ],
  "/app/step11" : [
    { "Top": "/app" },
    { "出演情報": "" },
  ],
  "/app/step12" : [
    { "Top": "/app" },
    { "その他": "" },
  ],
  "/app/step13" : [
    { "Top": "/app" },
    { "確認": "" },
  ],
  "/app/finish" : [
    { "Top": "/app" },
    { "完了": "" },
  ],
}
import * as React from "react"
import { ICaution } from "./ICaution"
import { renderText } from "../../../common/utils"

export const Caution = React.memo((props: ICaution) => {
  return (
    <div className="ir_info_text">
      <span>{renderText(props.caution)}</span>
    </div>
  )
})

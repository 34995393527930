import * as React from "react"
import { FunctionComponent, useMemo } from "react"
import { ITextForm } from "./ITextForm"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { Controller, useFormContext } from "react-hook-form"
import { IconButton, TextField, Tooltip } from "@material-ui/core"
import HelpIcon from '@material-ui/icons/Help'
import { formatItemId } from "../../../common/utils"

export const TextForm: FunctionComponent<ITextForm> = (props) => {
  const { register, control, formState: {errors} } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style, after_text } = props

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span className="required">*</span>
    }
    return retVal
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"} id={formatItemId(name)}>
      {title ? (
        <div className={placeholder ? "input_title_wrap has_placeholder" : "input_title_wrap"}>
          <Title title={title} />
          {renderRequired()}
          {placeholder ? (
            <Tooltip title={placeholder}>
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          ) : undefined}
        </div>
      ) : null}
      {description ? <Description description={description} /> : null}
      <Controller
        name={name}
        control={control}
        // defaultValue={value}
        rules={rules}
        render={({ field }) => <TextField
          variant="outlined"
          id={id.toString()}
          name={name}
          type={"text"}
          defaultValue={value}
          placeholder={placeholder}
          helperText={errors[name]?.message}
          // autoComplete={"off"}
          {...register(name, rules)} />}
      />
      {after_text ? <div className="after_text">{after_text}</div> : null}
      {caution ? <div style={{width: "100%"}}><Caution caution={caution} /></div> : null}
    </div>
  )
}

import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/login.css"
import { Button, CircularProgress, Paper, TextField } from "@material-ui/core"
import { FormProvider, useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Link, navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { LOGIN_SUCCESS } from "../../../store/auth/actionType"
import { appLogin, get, getHomeData, isAdmin, isBrowser, unAuthPost } from "../../../common/utils"
import { BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../common/constants"
import { useCommonData } from "../../../common/customHooks"
import { RELATIVE_PATH } from "../../../common/routePath"
import { CATEGORIES, LOST_ACCOUNT, MESSAGE_NOTICES, PRODUCTS, SUB_CATEGORIES } from "../../../common/apiConstants"
import Dexie from "dexie"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import { TextForm } from "../../molecules/TextForm/TextForm"
import Logo from "../../../images/common/vendin.svg"

const Login: FunctionComponent = (props) => {
  const [_, setCommonData] = useCommonData()
  const [loading, setLoading] = useState(false)
  const [lostLoading, setLostLoading] = useState(false)
  const [lostSuccess, setLostSuccess] = useState(false)
  const [lostModal, setLostModal] = useState(false)
  const dispatch = isBrowser ? useDispatch() : null
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        login_id: yup.string().required("必須"),
        login_password: yup.string().required("必須")
      })
    )
  })
  const accountLostMethods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        company_name: yup.string().required("必須"),
        account_name: yup.string().required("必須")
      })
    )
  })

  useEffect(() => {
    if (!lostModal) {
      accountLostMethods.setValue("company_name", "")
      accountLostMethods.setValue("account_name", "")
      accountLostMethods.setValue("account_email", "")
    }
  }, [lostModal])

  const { handleSubmit, control, formState: { errors } } = methods
  const { handleSubmit : accountLostSubmit, formState: { errors : account_error } } = accountLostMethods

  const onClick = async (data) => {
    setLoading(true)
    await appLogin(data.login_id, data.login_password).then(async response => {
      if (!response) {
        alert("ログインに失敗しました")
        setLoading(false)
        return false
      }
      dispatch({
        type: LOGIN_SUCCESS
      })

      // db作成
      const dixDb = new Dexie("vendinDB")
      dixDb.version(1).stores({
        productForm: 'formName',
      })

      // カテゴリ取得
      const categories = await get(CATEGORIES, {}).catch(e => console.log("CATEGORIES", e))
      // カテゴリ取得（サブカテゴリ含む）
      const subCategories = await get(SUB_CATEGORIES, {}).catch(e => console.log("SUB_CATEGORIES", e))
      // homeの件数取得
      const homeUnread = await getHomeData()

      // ヘッダー下部に表示するメッセージ取得
      let headerBottomData
      await get(MESSAGE_NOTICES, {params: { page: 1}}).then(res => {
        headerBottomData = {
          news_release: {
            id: res && res.data.length >= 1 ? res.data[0].id : "",
            type: 0,
            title: res && res.data.length >= 1 ? res.data[0].title : "",
            created_at: res && res.data.length >= 1 ? res.data[0].created_at : ""
          }
        }
      }).catch(e => console.log("MESSAGE_NOTICES", e))

      // 商品一覧取得
      const products = await get(PRODUCTS, {params: { approve : 1}}).catch(e => console.log("PRODUCTS", e))

      // common情報をlocalstrageに詰める // TODO:ヘッダーのAPIができたら修正
      let headerData = {
        categories: categories,
        unread_dealing: homeUnread.dealing_message_unread_count ? homeUnread.dealing_message_unread_count : 0,
        unread_message: homeUnread.notice_unread_count ? homeUnread.notice_unread_count : 0,
        is_admin: isAdmin
      }

      let sideBarData = {
        is_open: false,
        product_count: products.total,
        categorys: subCategories
      }

      let commonData = {
        header: headerData,
        header_lower: headerBottomData,
        side_bar: sideBarData
      }

      // @ts-ignore
      setCommonData(commonData)

      switch (parseInt(response.type)) {
        case BUYER:
        case BUYER_ADMIN:
          navigate(RELATIVE_PATH.BUYER_TOP)
          break
        case MAKER:
        case MAKER_ADMIN:
          navigate(RELATIVE_PATH.MAKER_TOP)
          break
        default:
          navigate(RELATIVE_PATH.ADMIN_TOP)
        // navigate("/")
      }
    }).catch(e => {
      console.log("catch e", e)
      alert("ログインに失敗しました")
    })
  }

  const onSubmitLostAccount = async (data) => {
    setLostLoading(true)
    let paramObj = {}
    paramObj.company_name = data.company_name
    paramObj.user_name = data.account_name
    paramObj.email = data.account_email

    await unAuthPost(LOST_ACCOUNT, paramObj).then(res => {
      setLostSuccess(true)
    }).catch(e => {
      console.log("LOST_ACCOUNT ERROR", e)
    }).finally(() => {
      setLostLoading(false)
    })
  }

  return (
    <div className="login_wrapper">
      <h1 className="vendin"><img src={Logo} alt="VENDIN" width={250} /></h1>
      <Paper elevation={3} className="login_paper">
        <h2 className="login_title">会員ログイン</h2>
        <FormProvider {...methods}>
          <form method="post" autoComplete="on" onSubmit={handleSubmit(onClick)}>
            <div className="login_id">
              <Controller
                name="login_id"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField
                  id="login_id"
                  variant="outlined"
                  color="secondary"
                  placeholder="account@vendin.co.jp"
                  size="small"
                  error={errors.login_id ? true: false}
                  helperText={errors.login_id?.message}
                  fullWidth
                  {...field} />}
              />
            </div>
            <div className="login_password">
              <Controller
                name="login_password"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField
                  id="login_password"
                  variant="outlined"
                  color="secondary"
                  placeholder="パスワード"
                  size="small"
                  type="password"
                  error={errors.login_password? true: false}
                  helperText={errors.login_password?.message}
                  fullWidth
                  {...field} />}
              />
            </div>
            <div className="lost_login">ID・パスワードを紛失した方は<span className="lost_account" onClick={() => setLostModal(true)}>こちら</span></div>
            <Button variant="contained" size="large" className="login_button green_btn" type="submit" startIcon={loading ? <CircularProgress size={20} color={"inherit"} /> : null}>
              ログイン
            </Button>
          </form>
          <div>
            <div className="login_application"><span>アカウント登録</span>に関しては<Link to={"https://vendin.co.jp/add_account"} target="_blank">こちら</Link>からお問い合わせください</div>
            <div className="login_application"><span>資料請求</span>に関しては<Link to={"https://vendin.co.jp#section-21"} target="_blank">こちら</Link>からお問い合わせください</div>
          </div>
        </FormProvider>

        <Modal
          className="confirm_modal account_modal"
          open={lostModal}
          onClose={() => {
            setLostModal(false)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={lostModal}>
            <div className="modal_body">
              {lostSuccess ? (
                <>
                  <h2 className="lost_account_title">お問い合わせありがとうございます。<br />入力されたメールアドレス宛にご連絡差し上げます。</h2>
                  <Button
                    variant="contained"
                    size="large"
                    className="login_button"
                    onClick={() => {
                      setLostModal(false)
                      setLostSuccess(false)
                    }}
                  >
                    閉じる
                  </Button>
                </>
              ) : (
                <>
                  <h2 className="lost_account_title">アカウント情報の確認が必要になります。<br />以下の項目を入力して「送信」ボタンを押してください。</h2>
                  <FormProvider {...accountLostMethods}>
                    <form method="post" autoComplete="on" onSubmit={accountLostSubmit(onSubmitLostAccount)}>
                      <div className="account_input_wrap">
                        <div className="company_name">
                          <TextForm
                            id="company_name"
                            name="company_name"
                            title="会社名"
                            placeholder="株式会社○○○"
                            rules={{ required: true }}
                          />
                        </div>
                        <div className="account_name">
                          <TextForm
                            id="account_name"
                            name="account_name"
                            title="氏名"
                            placeholder="VENDIN 花子"
                            rules={{ required: true }}
                          />
                        </div>
                        <div className="account_email">
                          <TextForm
                            id="account_email"
                            name="account_email"
                            title="メールアドレス"
                            placeholder="account@vendin.co.jp"
                          />
                        </div>
                      </div>
                      <Button variant="contained" size="large" className="login_button" type="submit" startIcon={lostLoading ? <CircularProgress size={20} color={"inherit"} /> : null}>
                        送信
                      </Button>
                    </form>
                  </FormProvider>
                </>
              )}
            </div>
          </Fade>
        </Modal>
      </Paper>
      <div className="copyright">©2021 VENDIN Co., Ltd. All Rights Reserved</div>
    </div>
  )
}

export default Login

// お知らせタイプ
export const NEWS_TYPE = {
  0: "お知らせ",
  1: "注意"
} as const
export type NewsType = typeof NEWS_TYPE[keyof typeof NEWS_TYPE]

// 承認ステータス
export const NOT_APPROVE = 0
export const UN_APPROVE = 1
export const APPROVED = 2
export const DRAFT = 99
export const APPROVAL_TYPES = {
  0: "審査中",
  1: "差し戻し",
  2: "承認済",
  99: "下書き",
} as const
export type ApprovalType = typeof APPROVAL_TYPES[keyof typeof APPROVAL_TYPES]

export const SELLING_TYPES = {
  0: "非公開",
  1: "公開",
} as const
export type SellingType = typeof SELLING_TYPES[keyof typeof SELLING_TYPES]

// 商談ステータス
export const DEALING_TYPE_ROUTE = 0
export const DEALING_TYPE_CLOSE = 1
export const DEALING_TYPE_ADOPT = 9
export const DEALING_TYPES = {
  0: "商談中",
  1: "商談終了",
  9: "商談成立"
} as const
export type DealingTypes = typeof DEALING_TYPES[keyof typeof DEALING_TYPES]

// ユーザータイプ
export const BUYER = 0
export const BUYER_ADMIN = 1
export const MAKER = 10
export const MAKER_ADMIN = 11
export const ADMIN = 99
export const USER_TYPE = {
  0: "バイヤー",
  1: "バイヤー管理者",
  10: "メーカー",
  11: "メーカー管理者",
  99: "管理者"
} as const
export type UserType = typeof USER_TYPE[keyof typeof USER_TYPE]

export const COMPANY_TYPES = {
  0: "バイヤー",
  1: "メーカー",
} as const
export type CompanyType = typeof COMPANY_TYPES[keyof typeof COMPANY_TYPES]

export const WITHDRAW_TYPES = {
  0: "有効",
  1: "退会",
} as const
export type WithdrawType = typeof WITHDRAW_TYPES[keyof typeof WITHDRAW_TYPES]

export const PREFECTURES = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
]

export const EXCEPTION_NAME = [
  "wholesale_price",
  "broadcast_price",
  "regular_price",
  "regular_price_type",
  "stock",
  "stock_status"
]